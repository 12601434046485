.navbar {
    height: auto;
    background-color: white !important;
    font-weight: bold;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding: 5px;
    z-index: 100;
    margin-top: 25px;
}

.navbar-brand {
    font-size: 35px;
    font-weight: bold;
    background-image: linear-gradient(to right, #2600ff, #ff8c00);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.logo {
    padding-left: 5px;
}

.navbar-nav {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.navbar-nav .nav-link {
    border: 1px solid transparent;
    /* Initial border style */
    transition: border-color 0.3s;
    /* Smooth transition effect */
}

.navbar-nav .nav-link:hover {
    border-color: black;
    /* Border color on hover */
}

