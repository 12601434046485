.top-header {
    background-color: white;
    /* Background color */
    color: black;
    /* Text color */
    text-align: right;
    /* Center-align text */
    padding: 10px;
    /* Add padding for spacing */
    font-size: 14px;
    /* Font size */
    position: fixed;
    /* Fixed position at the top */
    top: 0;
    /* Position at the top of the viewport */
    width: 100%;
    /* Full width */
    z-index: 1000;
    /* Ensure it's above other elements */
    display: flex;
    /* Use flexbox to align items */
    justify-content: space-between;
    /* Space items evenly */
}

.top-header.hidden {
    transform: translateY(-100%);
}

.social-icons {
    text-align: left;
    
    
}

.social-icons a {
    color: black;
    margin-right: 10px;
    /* Add margin between social icons */
}

.social-icon-divider {
    margin-right: 10px;
    /* Add margin between social icons */
}