
.link {
    text-decoration: none;
}

#cta {
    background-image: linear-gradient(to right, #2600ff, #ff8c00);
    margin-top: 5px;

}

.heroGrid {
    color: white;
    
}
.Divider {
    padding: 30px;
}

.book {
    text-align: center;
    padding-top: 25px;

}
