.contact-form {
    padding: 20px;

}


#response {
    padding: 25px;
    color: green;
    text-align: center;
    font-size: larger;

}

#submit:hover {
    transform: scale(1.2);
    box-shadow: 0 0 10px rgb(59, 28, 231);
}

.contact {
    padding: 20px;
}

#submit {
    background-image: linear-gradient(to right, #2600ff, #ff8c00)
}