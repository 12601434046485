.fluid {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    /* Add this to remove any default inline behavior */
    margin-left: auto;
    /* Center the image horizontally */
    margin-right: auto;
    /* Center the image horizontally */
}

@media (min-width: 768px) {
    .fluid {
        max-width: 70%;
        /* Increase the width for screen widths >= 768px */
    }
}

@media (min-width: 1024px) {
    .fluid {
        max-width: 50%;
        /* Further increase the width for screen widths >= 1024px */
    }
}

.aboutUS {
    text-align: center;
    font-family: roboto,helveticaneue,helvetica neue,helvetica-neue,Helvetica,Arial,sans-serif;
    font-size: larger;
    
}

.about {
    margin-top: 20px;
    font-weight: bold !important;
}